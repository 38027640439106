export default {
  idioma: 'Português',
  buttons: {
    next: 'Seguinte',
    start: 'Inicio',
    exit: 'Sair',
    back: 'Voltar',
    confirm: 'Confirmar',
    Erase: 'Apagar',
  },
  labels: {
    fieldRequired: 'Campo obrigatório',
    fullName: 'Nome Completo',
    id: 'DNI / NIE/ NIF',
  },
  form: {
    entryData: 'Introduza os seus dados',
    barcode: 'Código de barras',
  },
  signed: 'Assinatura OK!',
  scan: 'Ler o código de barras',
  manualScan: 'Ou introduza código de barras manualmente',
  error: 'Este código de barras não coincide com a expedição que deseja receber, tente ler outro código de barras.',
  info:
    'Se verificar problemas de leitura do código de barras, pode ligar o flash do dispositivo móvel ou procure mais iluminação.',
  urlNotFound: 'Este link ainda não foi ativado ou já não está disponível.',
  thanks: 'Obrigado!',
  received: 'O seu envio foi rececionado',
  errorUploading: {
    title: 'Ups! Verificamos um erro',
    subtitle: 'Por favor, faça a gestão da sua entrega com o seu motorista.',
  },
};
