export const white = '#FFFFFF';
export const black = '#000000';

export const blueGLS = {
  50: '#9497AE',
  100: '#7E829D',
  200: '#696D8D',
  300: '#54597D',
  400: '#3E446D',
  500: '#292F5D',
  600: '#141B4D',
  700: '#131946',
  800: '#111740',
  900: '#0F1439',
};

export const blackBerryGLS = {
  50: '#AE7F99',
  100: '#A06A88',
  200: '#935477',
  300: '#853F66',
  400: '#782A55',
  500: '#6B1544',
  600: '#62143E',
  700: '#581238',
  800: '#4E1032',
  900: '#450E2C',
};

export const yellowGLS = {
  50: '#FFDE8F',
  100: '#FFD87C',
  200: '#FFD369',
  300: '#FFCD57',
  400: '#FFC844',
  500: '#FFC332',
  600: '#E8B22E',
  700: '#D1A029',
  800: '#BA8E25',
  900: '#A37D20',
};

export const green = {
  50: '#E3F9E5',
  100: '#C1EAC5',
  200: '#A3D9A5',
  300: '#7BC47F',
  400: '#57AE5B',
  500: '#3F9142',
  600: '#2F8132',
  700: '#207227',
  800: '#0E5814',
  900: '#05400A',
};

export const grey = {
  50: '#F7F7F7',
  100: '#E1E1E1',
  200: '#CFCFCF',
  300: '#B1B1B1',
  400: '#9E9E9E',
  500: '#7E7E7E',
  600: '#626262',
  700: '#515151',
  800: '#3B3B3B',
  900: '#222222',
};

export const purple = {
  50: '#EAE2F8',
  100: '#CFBCF2',
  200: '#A081D9',
  300: '#8662C7',
  400: '#724BB7',
  500: '#653CAD',
  600: '#51279B',
  700: '#421987',
  800: '#34126F',
  900: '#240754',
};

export const red = {
  50: '#FFEEEE',
  100: '#FACDCD',
  200: '#F29B9B',
  300: '#E66A6A',
  400: '#D64545',
  500: '#BA2525',
  600: '#A61B1B',
  700: '#911111',
  800: '#780A0A',
  900: '#610404',
};

export const yellow = {
  50: '#FFFAEB',
  100: '#FCEFC7',
  200: '#F8E3A3',
  300: '#F9DA8B',
  400: '#F7D070',
  500: '#E9B949',
  600: '#C99A2E',
  700: '#A27C1A',
  800: '#7C5E10',
  900: '#513C06',
};

export const primary = {
  main: blackBerryGLS,
  light: '#F6F9FD',
  // dark: '#0B48A0',
  dark: '#6B1544',
};
