import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';

const useStyles = makeStyles(() => ({
  menuButton: {
    position: 'absolute',
  },
  children: {
    width: '100%',
    textAlign: 'center',
  },
}));

const Topbar = props => {
  const classes = useStyles();

  const { children, center } = props;

  return (
    <AppBar color="primary">
      <Toolbar>
        {center && <div className={classes.children}>{children}</div>}
        {!center && children}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Topbar.defaultProps = {};

export default Topbar;
