import React, { useContext } from 'react';
import MobileStepper from '@material-ui/core/MobileStepper';
import { Button, Dialog, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Cookies from 'js-cookie';
import { StoreContext } from '../stores/store';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const tutorialSteps = [
  {
    label: 'Paso 1',
    imgPath: {
      es: '/images/es/Screen-1.svg',
      pt: '/images/pt/Screen-1.svg',
    },
  },
  {
    label: 'Paso 2',
    imgPath: {
      es: '/images/es/Screen-2.svg',
      pt: '/images/pt/Screen-2.svg',
    },
  },
  {
    label: 'Paso 3',
    imgPath: {
      es: '/images/es/Screen-3.svg',
      pt: '/images/pt/Screen-3.svg',
    },
  },
  {
    label: 'Paso 4',
    imgPath: {
      es: '/images/es/Screen-4.svg',
      pt: '/images/pt/Screen-4.svg',
    },
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 420,
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: theme.spacing(3),
    outline: 'none',
    zIndex: 2000,
  },
  media: {
    padding: theme.spacing(1, 2),
    height: 180,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto',
    },
  },
  content: {
    padding: theme.spacing(1, 2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1, 2, 2, 1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Tutorial = observer(() => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();
  const { processStore } = useContext(StoreContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const lngDetected = i18n.languages.filter(lng => {
    return ['es', 'pt'].includes(lng);
  })[0];

  const handleClose = () => {
    Cookies.set('consent', 'true');
    processStore.hideTutorial();
    setActiveStep(0);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Dialog fullScreen open={processStore.isTutorialOpen} onClose={handleClose} TransitionComponent={Transition}>
      <img
        className={classes.img}
        src={tutorialSteps[activeStep].imgPath[lngDetected]}
        alt={tutorialSteps[activeStep].label}
      />
      <MobileStepper
        steps={maxSteps}
        position="bottom"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          activeStep < maxSteps - 1 ? (
            <Button
              size="small"
              onClick={handleNext}
              color="secondary"
              variant="contained"
              disabled={activeStep === maxSteps - 1}
            >
              {t('buttons.next')}
            </Button>
          ) : (
            <Button size="small" color="secondary" onClick={handleClose} variant="contained">
              {t('buttons.start')}
            </Button>
          )
        }
        backButton={
          activeStep === 0 ? (
            <Button size="small" color="secondary" onClick={handleClose} variant="contained">
              {t('buttons.exit')}
            </Button>
          ) : (
            <Button size="small" onClick={handleBack} disabled={activeStep === 0} color="secondary" variant="contained">
              {t('buttons.back')}
            </Button>
          )
        }
      />
    </Dialog>
  );
});

export default Tutorial;
