import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.common.white,
    '&:hover:not($disabled)': {
      backgroundColor: palette.background.default,
    },
  },
};
