import palette from '../palette';

export default {
  root: {
    backgroundColor: palette.common.white,
  },
  indicator: {
    height: '4px',
  },
};
