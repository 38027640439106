export default {
  idioma: 'Español',
  buttons: {
    next: 'Siguiente',
    start: 'Empezamos',
    exit: 'Salir',
    back: 'Atrás',
    confirm: 'Confirmar',
    Erase: 'Borrar',
  },
  labels: {
    fieldRequired: 'Campo requerido',
    fullName: 'Nombre Completo',
    id: 'DNI / NIE/ NIF',
  },
  form: {
    entryData: 'Introduce tus datos',
    barcode: 'Código de barras',
  },
  signed: '¡Firmado con éxito!',
  scan: 'Escanea el código de barras',
  manualScan: 'O introdúcelo manualmente',
  error:
    'Este código de barras no coincide con la expedición que intentas recibir, prueba a escanear otro código de barras.',
  info:
    'Si tienes problemas de lectura del código de barras, puedes enceder el flash del dispositivo o intentar que haya más iluminación.',
  urlNotFound: 'Este enlace aún no está habilitado o ya no esta disponible.',
  thanks: '¡Gracias!',
  received: 'Tu envío ya ha sido recepcionado',
  errorUploading: {
    title: '¡Ups! Se ha producido un error.',
    subtitle: 'Por favor, gestiona la entrega con tu mensajero.',
  },
};
