import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  loading: {
    height: 'calc(100vh)',
  },
}));

const Loading = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="center" alignItems="center" className={classes.loading}>
      <CircularProgress />
    </Grid>
  );
};

export default Loading;
