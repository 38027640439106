import React, { useContext, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Typography, Paper, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { observer } from 'mobx-react-lite';
import Topbar from '../components/Topbar';
import { ReactComponent as Logo } from '../assets/images/gls_logo_inverter.svg';
import InputBarcode from '../components/InputBarcode';
import { StoreContext } from '../stores/store';
import { SCAN_STATUS } from '../stores/processStore';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: theme.mixins.toolbar.minHeight,
    padding: theme.spacing(2, 2, 0, 2),
  },
  icons: {
    display: 'flex',
    // textAlign: 'center',
    marginBottom: '0.35em',
  },
  right: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '5px',
  },
  center: {
    display: 'flex',
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5px',
  },
  mainArea: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: '10px',
  },
  video: {
    width: '100%',
    height: '150px',
    border: '1px solid gray',
    objectFit: 'cover',
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  icon: {
    height: '32px',
    width: '32px',
    fontSize: '32px',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const ScannerPage = observer(() => {
  const classes = useStyles();
  const [t] = useTranslation();
  const { processStore } = useContext(StoreContext);
  const { scanStore, scanStatus } = processStore;

  useEffect(() => {
    scanStore.startDecodingCamera({ videoId: 'video' });
    return () => {
      scanStore.stopDecodingCamera();
    };
  }, [scanStore]);

  const handleManualInput = barcode => {
    scanStore.manualFind(barcode);
  };

  return (
    <>
      <Topbar center={true}>
        <Logo />
      </Topbar>
      <Paper className={classes.mainArea} elevation={3}>
        <Typography color="inherit" variant="h5" gutterBottom>
          {t('scan')}
        </Typography>
        <div>
          <video id="video" className={classes.video} />
        </div>
        <Typography color="inherit" align="left" gutterBottom>
          {t('manualScan')}
        </Typography>
        <InputBarcode onClick={handleManualInput} />
      </Paper>
      <div className={classes.alert}>
        {scanStatus === SCAN_STATUS.ERROR && <Alert severity="error">{t('error')}</Alert>}
      </div>
      <div className={classes.alert}>
        <Alert severity="info">{t('info')}</Alert>
      </div>
    </>
  );
});

// ScannerPage.propTypes = {};

export default ScannerPage;
