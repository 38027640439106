import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

const Space = () => {
  const classes = useStyles();
  return <div className={classes.grow}></div>;
};

export default Space;
