import { white, black, red, blackBerryGLS, blueGLS, yellowGLS } from '../common/colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
    yellow: yellowGLS[500],
    blue: '#141B4D',
  },
  primary: {
    contrastText: white,
    main: blueGLS[600],
    light: blueGLS[300],
    dark: blueGLS[900],
  },
  secondary: {
    contrastText: white,
    main: blackBerryGLS[500],
    light: blackBerryGLS[100],
    dark: blackBerryGLS[800],
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: white,
    light: red[100],
    main: red[400],
    dark: red[700],
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#A6B1BB',
  },
  background: {
    default: '#f8fafc',
    dark: '#172B4D',
    paper: white,
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
};
