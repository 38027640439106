/* eslint-disable no-console */
import Debug from 'debug';
import { decorate, observable, autorun, action, runInAction, reaction } from 'mobx';
import { datetimes } from '@gls_spain/moment-options';
import { CodeReader, FORMATS, NotStoppedError, NotPausedError, PausedError } from '../service/code-reader';

const debug = Debug('GPS:scanStore');
/**
 * The status of the camera decoding.
 *
 * The store can only have one ongoing decoding at the same time.
 *
 * @type {{DECODING: string, PAUSED: string, STOPPED: string}}
 */
export const DECODING_STATUS = {
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED',
  DECODING: 'DECODING',
};

/**
 * The status of the barcode finding on the backend.
 *
 * The store can have multiple ongoing finds at the same time. If concurrentFinds > 0 then status is FINDING, else is READY.
 *
 * @type {{READY: string, FINDING: string}}
 */
export const FINDING_STATUS = {
  READY: 'READY',
  FINDING: 'FINDING',
};

/**
 * @callback scanAsyncFindFunction
 * @param {Object} options - An object with either a barcode or a QR code
 * @param {string} options.barcode - The barcode to use as query
 * @param {string} options.qrCode - The QR code to use as query
 */

const DECODE_DELAY_AFTER_SUCCESS = 3000;
const DECODE_DELAY_AFTER_ERROR = 1000;

/**
 * Reads barcodes and QR codes and uses them to find resources on the backend.
 *
 * @param {string} name - Name of the store for debugging purposes
 * @param {boolean} [readCode128=true] - Try to read Code 128 barcodes.
 * @param {boolean} [readITF=true] - Try to read Interleaved 2 of 5 barcodes.
 * @param {boolean} [readQR=true] - Try to read QR codes.
 * @param {scanAsyncFindFunction} asyncFindFunction - Async function that receives a barcode or QR code and returns the resource from the backend.
 */
class ScanStore {
  constructor({ name = 'scanStore', readCode128 = true, readITF = true, readQR = true, asyncFindFunction }) {
    this.name = name;
    this.asyncFindFunction = asyncFindFunction;
    this.codeReader = new CodeReader({ readCode128, readITF, readQR });
    this.concurrentFinds = 0;
    autorun(this._syncDecodingStatusWithCodeReader);
    autorun(this._syncFindingStatusWithConcurrentFinds);
    this.decodingResult = undefined;
    this.findingResult = undefined;
    this.decodeDelayTimeoutId = undefined;

    reaction(
      () => this.decodingStatus,
      decodingStatus => debug('decoding status: %s', decodingStatus),
    );
    reaction(
      () => this.findingStatus,
      findingStatus => debug('finding status: %s', findingStatus),
    );
  }

  _clearDecodeTimeoutId = () => {
    if (this.decodeDelayTimeoutId !== undefined) {
      clearTimeout(this.decodeDelayTimeoutId);
      this.decodeDelayTimeoutId = undefined;
    }
  };

  _syncDecodingStatusWithCodeReader = () => {
    const newStatus = this.codeReader.status;
    runInAction(() => {
      this.decodingStatus = newStatus;
    });
  };

  _syncFindingStatusWithConcurrentFinds = () => {
    const newStatus = this.concurrentFinds === 0 ? FINDING_STATUS.READY : FINDING_STATUS.FINDING;
    runInAction(() => {
      this.findingStatus = newStatus;
    });
  };

  _processDecodingSuccess = ({ format, text }) => {
    this.decodingResult = { at: datetimes.now(), format, text };
    debug('decoding success: %O', this.decodingResult);
    const barcode = format === FORMATS.CODE_128 || format === FORMATS.ITF ? text : undefined;
    const qrCode = format === FORMATS.QR_CODE ? text : undefined;
    this.concurrentFinds += 1;

    this.asyncFindFunction({ barcode, qrCode })
      .then(success => {
        runInAction(() => {
          debug('auto find success: %O', success);
          this.concurrentFinds -= 1;
          this.findingResult = { at: datetimes.now(), success };
          this._clearDecodeTimeoutId();
          this.decodeDelayTimeoutId = setTimeout(() => {
            debug(`waiting ${DECODE_DELAY_AFTER_SUCCESS} before decoding again`);
            this.resumeDecodingCamera();
          }, DECODE_DELAY_AFTER_SUCCESS);
        });
      })
      .catch(error => {
        runInAction(() => {
          debug('auto find error: %O', error);
          this.concurrentFinds -= 1;
          this.findingResult = { at: datetimes.now(), error };
          this._clearDecodeTimeoutId();
          this.decodeDelayTimeoutId = setTimeout(() => {
            debug(`waiting ${DECODE_DELAY_AFTER_ERROR} before decoding again`);
            this.resumeDecodingCamera();
          }, DECODE_DELAY_AFTER_ERROR);
        });
      });
  };

  _processDecodingError = error => {
    if (error instanceof NotStoppedError) {
      debug('decoding error: tried to start decoding camera when decoding status was not STOPPED');
    } else if (error instanceof NotPausedError) {
      debug('decoding error: tried to resume decoding camera when decoding status was not PAUSED');
    } else if (error instanceof PausedError) {
      // ignore
    } else {
      debug('decoding error: %O', error);
      console.error(error);
      // todo detectar el device not found
      // todo detectar errores del zxing
    }
  };

  startDecodingCamera({ videoId }) {
    this.codeReader.start({ videoId }).then(this._processDecodingSuccess, this._processDecodingError);
  }

  resumeDecodingCamera() {
    this.codeReader.resume().then(this._processDecodingSuccess, this._processDecodingError);
  }

  pauseDecodingCamera() {
    this._clearDecodeTimeoutId();
    this.codeReader.pause();
  }

  stopDecodingCamera() {
    this._clearDecodeTimeoutId();
    this.codeReader.stop();
  }

  manualFind({ barcode, qrCode }) {
    this.asyncFindFunction({ barcode, qrCode })
      .then(success => {
        runInAction(() => {
          debug('manual find success: %O', success);
          this.concurrentFinds -= 1;
          this.findingResult = { at: datetimes.now(), success };
        });
      })
      .catch(error => {
        runInAction(() => {
          debug('manual find error: %O', error);
          this.concurrentFinds -= 1;
          this.findingResult = { at: datetimes.now(), error };
        });
      });
  }
}

decorate(ScanStore, {
  decodingStatus: observable,
  findingStatus: observable,
  decodingResult: observable,
  findingResult: observable,
  _processDecodingSuccess: action.bound,
  manualFind: action.bound,
});

export default ScanStore;
