import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessImg } from '../assets/images/success.svg';

const useStyles = makeStyles(theme => ({
  img: {
    width: '280px',
    maxWidth: '100%',
    margin: '0 auto',
  },
  margin: {
    marginTop: theme.spacing(2),
  },
}));

const Successful = () => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <div>
      <div className={classes.img}>
        <SuccessImg />
      </div>
      <Typography variant="h3" align="center" gutterBottom>
        {t('signed')}
      </Typography>
    </div>
  );
};

export default Successful;
