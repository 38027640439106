import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  full: {
    height: 'calc(100vh - 112px)',
  },
  center: {
    margin: '0 auto',
    width: 'fit-content',
  },
  canvas: {
    border: '1px solid grey',
  },
  wrapper: {
    position: 'relative',
    margin: '0 auto',
  },
  mr: {
    marginRight: theme.spacing(2),
  },
}));

const Signature = ({
  onClean,
  onChange,
  onSave,
  pointGroupArray,
  width = window.innerWidth,
  height = window.innerHeight,
}) => {
  let sigCanvas;
  const classes = useStyles();
  // const [size] = useResizeViewport({ width, height });
  const [t] = useTranslation();

  const handleClean = () => {
    sigCanvas.clear();
    if (onClean) {
      onClean();
    }
    if (onChange) {
      onChange('', '');
    }
  };

  const handleOnEnd = () => {
    if (onChange) {
      onChange(sigCanvas.toDataURL(), sigCanvas.toData());
    }
  };

  // const handleOnSave = () => {
  //   if (onSave) {
  //     onSave(sigCanvas.toDataURL(), sigCanvas.toData());
  //   }
  // };

  useEffect(() => {
    if (pointGroupArray) {
      sigCanvas.fromData(pointGroupArray);
    }
    return () => {
      if (sigCanvas && onChange && !sigCanvas.isEmpty()) {
        onChange(sigCanvas.toDataURL(), sigCanvas.toData());
      }
    };
  }, [onChange, sigCanvas, pointGroupArray]);

  return (
    <div className={classes.wrapper} style={{ width: `${width}px`, height: `${height}px` }}>
      <div className={classes.button}>
        <Button variant="outlined" onClick={handleClean} className={classes.mr}>
          {t('buttons.erase')}
        </Button>
        {/*<Button variant="outlined" onClick={handleOnSave}>*/}
        {/*  save*/}
        {/*</Button>*/}
      </div>
      <SignatureCanvas
        ref={ref => {
          sigCanvas = ref;
        }}
        penColor="black"
        backgroundColor="rgba(255,255,255)"
        canvasProps={{
          width: `${width}px`,
          height: `${height}px`,
          className: classes.canvas,
        }}
        onEnd={handleOnEnd}
      />
    </div>
  );
};

Signature.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  onClean: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  pointGroupArray: PropTypes.array,
};

export default Signature;
