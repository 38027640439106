import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles, Typography, Button, FormControl, FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';

import Signature from './Signature';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

function Form({ onSubmit }) {
  const classes = useStyles();
  const [t] = useTranslation();
  const targetRef = useRef();
  const nifRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  const handleOnSubmitFormik = (values, actions) => {
    // console.log('values', values);
    actions.setSubmitting(false);
    onSubmit(values);
  };

  const handleValidation = values => {
    const errors = {};
    if (values.name === '') {
      errors.name = t('labels.fieldReuired');
    }
    if (values.nif === '') {
      errors.nif = t('labels.fieldReuired');
    }
    if (values.image === '') {
      errors.image = t('labels.fieldReuired');
    }
    return errors;
  };

  return (
    <div ref={targetRef}>
      <Formik
        initialValues={{ name: '', nif: '', image: '' }}
        onSubmit={handleOnSubmitFormik}
        validate={handleValidation}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography variant="h5" gutterBottom>
                {t('form.entryData')}
              </Typography>
              <TextField
                className={classes.marginBottom}
                fullWidth
                id="name"
                name="name"
                label={t('labels.fullName')}
                variant="outlined"
                value={values.name}
                margin="dense"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name}
              />
              <TextField
                className={classes.marginBottom}
                fullWidth
                id="nif"
                label={t('labels.id')}
                name="nif"
                variant="outlined"
                inputRef={nifRef}
                value={values.nif}
                margin="dense"
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.nif)}
                helperText={errors.nif && errors.nif}
              />
              <FormControl error={Boolean(errors.image)}>
                <Signature
                  width={dimensions.width.toString()}
                  height="275px"
                  onChange={data => {
                    if (nifRef.current) {
                      nifRef.current.blur();
                    }
                    setValues({
                      ...values,
                      image: data,
                    });
                    // setPod(data);
                  }}
                  className={classes.marginBottom}
                />
                <FormHelperText className={classes.marginBottom} id="component-error-text">
                  {errors.image && touched.image && errors.image}
                </FormHelperText>
              </FormControl>
              <Button fullWidth type="submit" variant="contained" color="secondary">
                {t('buttons.confirm')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
};

export default Form;
