import { decorate, observable, action, runInAction, reaction, toJS } from 'mobx';

export const STATUS = {
  LOADING: 'LOADING',
  FORM: 'FORM',
  CREATED: 'CREATED',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  SIGNED: 'SIGNED',
  ERROR: 'ERROR',
  ERROR_UPLOADING: 'ERROR_UPLOADING',
  SUCCESSFUL: 'SUCCESSFUL',
};

export const SCAN_STATUS = {
  IDLE: 'IDLE',
  ERROR: 'ERROR',
};

class ProcessStore {
  /**
   *
   * @param name Name of the store for debugging purposes
   * @param scanStore
   * @param apiClient
   */
  constructor({ scanStore, apiClient }) {
    this.id = undefined;
    this.scanStore = scanStore;
    this.scanStore.asyncFindFunction = this._scanStoreAsyncFindFunction;
    this.disposeScanStoreFindingResultReaction = reaction(
      () => scanStore.findingResult,
      this._scanStoreFindingResultReaction,
    );
    this.apiClient = apiClient;
    this.currentPod = undefined;
    this.scannedBarcode = undefined;
    this.status = STATUS.LOADING;
    this.scanStatus = SCAN_STATUS.IDLE;
    this.isTutorialOpen = false;
  }

  _scanStoreAsyncFindFunction = async ({ barcode }) => {
    if (toJS(this.currentPod).barcodes.includes(barcode)) {
      return Promise.resolve({ barcode });
    }
    return Promise.reject(new Error('No es la expedicion correcta'));
  };

  _scanStoreFindingResultReaction = ({ success, error }) => {
    if (error) {
      runInAction(() => {
        this.scanStatus = SCAN_STATUS.ERROR;
      });
    } else {
      const { barcode } = success;
      runInAction(() => {
        this.scannedBarcode = barcode;
        this.status = STATUS.FORM;
      });
    }
  };

  async getPod(id) {
    try {
      const {
        data: { get: currentPod },
      } = await this.apiClient.podStatus({
        get: {
          _id: id,
        },
      });
      runInAction(() => {
        this.currentPod = currentPod;
        const { status } = currentPod;
        this.status = status === 'open' ? STATUS.OPEN : STATUS[status.toUpperCase()];
      });
    } catch (e) {
      runInAction(() => {
        this.status = STATUS.ERROR;
      });
    }
  }

  async signPod(data) {
    runInAction(() => {
      this.status = STATUS.LOADING;
    });
    try {
      await this.apiClient.signPod({
        find: {
          _id: this.currentPod._id,
        },
        sign: {
          at: new Date(),
          geolocation: {
            type: 'Point',
            coordinates: [0, 0],
          },
          ...data,
        },
      });
      runInAction(() => {
        this.status = STATUS.SUCCESSFUL;
      });
    } catch (e) {
      runInAction(() => {
        this.status = STATUS.ERROR_UPLOADING;
      });
    }
  }

  reset() {
    this.getPod(this.id);
  }

  showTutorial() {
    runInAction(() => {
      this.isTutorialOpen = true;
    });
  }

  hideTutorial() {
    runInAction(() => {
      this.isTutorialOpen = false;
    });
  }
}

decorate(ProcessStore, {
  currentPod: observable,
  scannedBarcode: observable,
  scanStatus: observable,
  status: observable,
  isTutorialOpen: observable,
  getPod: action.bound,
  signPod: action.bound,
  reset: action.bound,
  showTutorial: action.bound,
  hideTutorial: action.bound,
});

export default ProcessStore;
