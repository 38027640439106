import React from 'react';
import PropTypes from 'prop-types';
import { InputBase, InputAdornment, Paper, makeStyles, IconButton } from '@material-ui/core';
import { BarcodeIcon } from '../assets/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: theme.mixins.toolbar.minHeight,
    padding: theme.spacing(2, 2, 0, 2),
  },
  icons: {
    textAlign: 'center',
    marginBottom: '0.35em',
  },
  mainArea: {
    backgroundColor: theme.palette.common.blue,
    color: theme.palette.common.white,
    borderRadius: '4px',
    padding: '10px',
  },
  video: {
    width: '100%',
    height: '150px',
    border: '1px solid gray',
    objectFit: 'cover',
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: '4px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
    borderRadius: `0 4px 4px 0`,
    backgroundColor: theme.palette.common.yellow,
    height: '35px',
    width: '35px',
    maxHeight: 'initial',
    justifyContent: 'center',
  },
  icon: {
    height: '32px',
    width: '32px',
    fontSize: '32px',
  },
  rootList: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(0.5),
  },
  titleWrapperItem: {
    flex: '1',
  },
  titleWrapperDivider: {
    marginLeft: '-7px',
    marginRight: '-7px',
  },
  iconCancel: {
    color: theme.palette.common.muted,
  },
  inset: {
    paddingLeft: theme.spacing(4),
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
}));

const InputBarcode = ({ onClick }) => {
  const classes = useStyles();
  const refInput = React.createRef();
  const [t] = useTranslation();

  function cleanAndFocusInput() {
    refInput.current.value = '';
    refInput.current.focus();
  }

  const handleClick = () => {
    const barcode = refInput.current.value;
    if (barcode !== '') {
      onClick({ barcode });
      cleanAndFocusInput();
    }
  };

  const handleEnter = e => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={t('form.barcode')}
        inputProps={{ 'aria-label': 'Barcode number' }}
        inputRef={refInput}
        onKeyPress={handleEnter}
      />
      <InputAdornment color="inherit" className={classes.iconButton}>
        <IconButton aria-label="toggle password visibility" onClick={handleClick} color={'inherit'}>
          <BarcodeIcon
            styles={{
              height: '28px',
              width: '28px',
            }}
          />
        </IconButton>
      </InputAdornment>
    </Paper>
  );
};

InputBarcode.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default InputBarcode;
