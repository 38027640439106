import React from 'react';
import { ReactComponent as Barcode } from './barcode-solid.svg';
import { ReactComponent as Help } from './question-circle-solid.svg';

export const BarcodeIcon = ({ className }) => (
  <Barcode
    style={{
      height: '22px',
      width: '22px',
      fontSize: '22px',
    }}
    className={className}
  />
);

export const HelpIcon = ({ className }) => (
  <Help
    style={{
      height: '22px',
      width: '22px',
      fontSize: '22px',
    }}
    className={className}
  />
);
