import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import ContactlessPod from './views/ContactlessPod';

import theme from './theme';
import './index.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Route exact path="/:id">
          <ContactlessPod />
        </Route>
      </Router>
    </ThemeProvider>
  );
}

export default App;
