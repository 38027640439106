import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, IconButton } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import Container from '@material-ui/core/Container';

import Topbar from '../components/Topbar';
import Page from '../components/Page';
import Form from '../components/Form';
import ScannerPage from './Scan';
import { ReactComponent as Logo } from '../assets/images/gls_logo_inverter.svg';
import { StoreContext } from '../stores/store';
import { STATUS } from '../stores/processStore';
import Error from './Error';
import Successful from './Successful';
// import Warning from './Warning';
import Loading from './Loading';
import FooterBar from '../components/FooterBar';
import ErrorUploading from './ErrorUploading';
import Signed from './Signed';
import CookiesNotification from '../components/CookiesNotification';
import { HelpIcon } from '../assets/icons';
import Space from '../components/Space';
import Tutorial from '../components/Tutorial';
import { useTranslation } from 'react-i18next';

const RenderViews = observer(() => {
  const { processStore } = useContext(StoreContext);
  const { status, signPod } = processStore;
  switch (status) {
    case STATUS.LOADING:
      return (
        <Page>
          <Loading />
        </Page>
      );
    case STATUS.FORM:
      return (
        <Page>
          <Form onSubmit={signPod} />
        </Page>
      );
    case STATUS.SUCCESSFUL:
      return (
        <Page>
          <Successful />
        </Page>
      );
    case STATUS.OPEN:
      return (
        <Page>
          <ScannerPage />
        </Page>
      );
    case STATUS.CREATED:
    case STATUS.CLOSED:
      return (
        <Page>
          <Error />
        </Page>
      );
    case STATUS.SIGNED:
      return (
        <Page>
          <Signed />
        </Page>
      );
    case STATUS.ERROR_UPLOADING:
      return (
        <Page>
          <ErrorUploading />
        </Page>
      );
    default:
      return (
        <Page>
          <Error />
        </Page>
      );
  }
});

const ContactlessPod = () => {
  const { processStore } = useContext(StoreContext);
  const { id } = useParams();

  useEffect(() => {
    processStore.id = id;
    processStore.getPod(id);
  }, [processStore, id]);

  return (
    <>
      <Topbar center>
        <Logo />
      </Topbar>
      <Container maxWidth="sm">
        <CookiesNotification />
        <Tutorial />
        <RenderViews />
      </Container>
      <FooterBar>
        <Typography variant="body2" color="inherit">
          Copyright © 2020 - GLS www.gls-spain.es
        </Typography>
        <Space />
        <IconButton onClick={processStore.showTutorial}>
          <HelpIcon />
        </IconButton>
      </FooterBar>
    </>
  );
};

export default ContactlessPod;
