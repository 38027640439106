import React from 'react';
import GPSApiClient from '../service/gps-api-client';
import ScanStore from './scanStore';
import ProcessStore from './processStore';

const baseURL = process.env.REACT_APP_BASE_URL;

class Store {
  constructor() {
    this.apiClient = new GPSApiClient({ baseURL });

    this.scanProcessStore = new ScanStore({
      readCode128: true,
      readITF: true,
      readQR: false,
    });

    this.processStore = new ProcessStore({
      scanStore: this.scanProcessStore,
      apiClient: this.apiClient,
    });
  }
}

const defaultStore = new Store();

export const StoreContext = React.createContext(defaultStore);

export const StoreProvider = ({ children, store }) => {
  return <StoreContext.Provider value={store || defaultStore}>{children}</StoreContext.Provider>;
};

export default defaultStore;
