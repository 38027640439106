/* eslint-disable max-classes-per-file */
import { decorate, observable, action, autorun } from 'mobx';
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType, NotFoundException } from '@zxing/library/esm5';
import Debug from 'debug';

const debug = Debug('GPS:code-reader');

export const STATUS = {
  STOPPED: 'STOPPED',
  PAUSED: 'PAUSED',
  DECODING: 'DECODING',
};

export const FORMATS = {
  CODE_128: 'CODE_128',
  ITF: 'ITF',
  QR_CODE: 'QR_CODE',
};

export class NotStoppedError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class NotPausedError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class PausedError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class Barcode {
  constructor(result) {
    const zxingFormat = result.getBarcodeFormat();
    if (zxingFormat === BarcodeFormat.CODE_128) {
      this.format = FORMATS.CODE_128;
    } else if (zxingFormat === BarcodeFormat.ITF) {
      this.format = FORMATS.ITF;
    } else if (zxingFormat === BarcodeFormat.QR_CODE) {
      this.format = FORMATS.QR_CODE;
    } else {
      this.format = '';
    }
    this.text = result.getText();
  }
}

export class CodeReader {
  constructor({ readCode128 = true, readITF = true, readQR = true }) {
    const barcodeFormats = [];
    if (readCode128) {
      barcodeFormats.push(BarcodeFormat.CODE_128);
    }
    if (readITF) {
      barcodeFormats.push(BarcodeFormat.ITF);
    }
    if (readQR) {
      barcodeFormats.push(BarcodeFormat.QR_CODE);
    }
    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, barcodeFormats);
    this.reader = new BrowserMultiFormatReader(hints);
    this.status = STATUS.STOPPED;
    autorun(() => debug('decoding status: %s', this.status));
  }

  _processDecodingResult = result => {
    if (this.status === STATUS.DECODING) {
      this.status = STATUS.PAUSED;
    }
    debug('decoding success: %O', result);
    return new Barcode(result);
  };

  _processDecodingError = error => {
    if (error instanceof NotFoundException) {
      if (this.status === STATUS.DECODING) {
        this.status = STATUS.PAUSED;
      }
      throw new PausedError();
    } else {
      debug('decoding error: %O', error);
    }
    this.status = STATUS.STOPPED;
    throw new Error(error.message);
  };

  /**
   * Starts the video and keeps decoding it (sets status to DECODING) until it finds a barcode, then it pauses (sets status to PAUSED) and returns the barcode.
   * @param {string } videoId - The id of the html video tag to embed the stream from the camera.
   * @returns {Promise<Barcode>} - The barcode found. Sets status to PAUSED.
   * @throws {NotStoppedError} Will throw if status is not STOPPED when called.
   * @throws {PausedError} Will throw when pause() is called while decoding. Sets status to PAUSED.
   * @throws {Error} Will throw on any other kind of error while decoding. Sets status to STOPPED.
   */
  start({ videoId }) {
    if (this.status !== STATUS.STOPPED) {
      return Promise.reject(new NotStoppedError());
    }
    this.status = STATUS.DECODING;
    this.videoId = videoId;
    return this.reader
      .decodeOnceFromVideoDevice(undefined, videoId)
      .then(this._processDecodingResult)
      .catch(this._processDecodingError);
  }

  /**
   * Resumes decoding the video (sets status to DECODING) until it finds a barcode, then it pauses again (sets status to PAUSED) and returns the barcode;
   * @returns {Promise<Barcode>} - The barcode found. Sets status to PAUSED.
   * @throws {NotPausedError} Will throw if status is not PAUSED when called.
   * @throws {PausedError} Will throw when pause() is called while decoding. Sets status to PAUSED.
   * @throws {Error} Will throw on any other kind of error while decoding. Sets status to STOPPED.
   */
  resume() {
    if (this.status !== STATUS.PAUSED) {
      return Promise.reject(new NotPausedError());
    }
    this.status = STATUS.DECODING;
    const videoElement = document.getElementById(this.videoId);
    return this.reader.decodeOnce(videoElement).then(this._processDecodingResult).catch(this._processDecodingError);
  }

  /**
   * Causes any ongoing decoding to reject with a PausedError. Has no effect if there was no ongoing decoding.
   */
  pause() {
    if (this.status === STATUS.DECODING) {
      this.reader.stopAsyncDecode();
    }
  }

  /**
   * Causes any ongoing decoding to reject with a PausedError and sets status to STOPPED.
   */
  stop() {
    if (this.status === STATUS.DECODING || this.status === STATUS.PAUSED) {
      this.reader.reset();
      this.videoId = undefined;
      this.status = STATUS.STOPPED;
    }
  }
}

decorate(CodeReader, {
  status: observable,
  _processDecodingResult: action,
  _processDecodingError: action,
  start: action,
  resume: action,
  pause: action,
  stop: action,
});
