import axios from 'axios';

export default class GPSApiClient {
  constructor(options) {
    this.axiosInstance = axios.create({
      baseURL: options.baseURL,
      timeout: options.timeout || 30000,
    });
  }

  async podStatus(body) {
    return this.axiosInstance.post('/pods/get', body);
  }

  async signPod(body) {
    return this.axiosInstance.post('/pods/sign', body);
  }
}
