// Palette
import palette from '../palette';

export default {
  root: {
    color: palette.common.white,
  },
  colorDefault: {
    backgroundColor: palette.common.white,
  },
  colorPrimary: {
    backgroundColor: palette.common.blue,
  },
  colorSecondary: {
    backgroundColor: palette.secondary,
  },
};
