import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  body: {
    padding: theme.spacing(2, 0, 2, 0),
  },
  toolbarBottom: {
    marginBottom: '64px',
  },
}));

const Page = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.body, classes.toolbarBottom)}>
      <div className={classes.toolbar} />
      {children}
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Page;
