import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
}));

const FooterBar = ({ children }) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>{children}</Toolbar>
    </AppBar>
  );
};

FooterBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default FooterBar;
