import { useEffect, useContext } from 'react';
import Cookies from 'js-cookie';

import { StoreContext } from '../stores/store';

const CookiesNotification = () => {
  const { processStore } = useContext(StoreContext);

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      processStore.showTutorial();
    }
  }, [processStore]);

  return null;
};

export default CookiesNotification;
